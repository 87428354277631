// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_navbarContainer__54CXx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  box-shadow: 0 0 10px rgba(187, 187, 187, 0.5);
  min-height: 80px;
}
.layout_navbarContainer__54CXx .layout_navLogo__0YZzT {
  width: 85px;
}

.layout_sidebarContainer__I8Gym {
  background: white;
  width: 250px;
  max-width: 250px;
  border-right: 1px solid #eef0f8;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  margin-top: 80px;
  padding-top: 30px;
  box-shadow: 0 6px 10px rgba(187, 187, 187, 0.5);
}
@media screen and (max-width: 767px) {
  .layout_sidebarContainer__I8Gym {
    width: 60px;
    max-width: 60px;
  }
  .layout_sidebarContainer__I8Gym .layout_listItemIcon__qaLYI {
    min-width: auto;
  }
}

.layout_mainContainer__G42Gv {
  height: calc(100vh - 80px);
  margin-left: 250px;
  margin-top: 80px;
  overflow: auto;
  width: calc(100% - 250px);
  padding: 20px;
}
@media screen and (max-width: 767px) {
  .layout_mainContainer__G42Gv {
    margin-left: 60px;
    width: calc(100% - 60px);
    padding: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/common/layout.module.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,6CAAA;EACA,gBAVO;AAOT;AAKE;EACE,WAAA;AAHJ;;AAOA;EACE,iBAAA;EACA,YAlBa;EAmBb,gBAnBa;EAoBb,+BAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,SAAA;EACA,gBA1BO;EA2BP,iBAAA;EACA,+CAAA;AAJF;AAME;EAbF;IAcI,WA7BgB;IA8BhB,eA9BgB;EA2BlB;EAKE;IACE,eAAA;EAHJ;AACF;;AAOA;EACE,0BAAA;EACA,kBAzCa;EA0Cb,gBA3CO;EA4CP,cAAA;EACA,yBAAA;EACA,aAAA;AAJF;AAME;EARF;IASI,iBA/CgB;IAgDhB,wBAAA;IACA,aAAA;EAHF;AACF","sourcesContent":["$navbar: 80px;\n$sidebarWidth: 250px;\n$smallSidebarWidth: 60px;\n\n.navbarContainer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: white;\n  box-shadow: 0 0 10px rgba(187, 187, 187, 0.5);\n  min-height: $navbar;\n\n  .navLogo {\n    width: 85px;\n  }\n}\n\n.sidebarContainer {\n  background: white;\n  width: $sidebarWidth;\n  max-width: $sidebarWidth;\n  border-right: 1px solid #eef0f8;\n  position: fixed;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  margin-top: $navbar;\n  padding-top: 30px;\n  box-shadow: 0 6px 10px rgba(187, 187, 187, 0.5);\n\n  @media screen and (max-width: 767px) {\n    width: $smallSidebarWidth;\n    max-width: $smallSidebarWidth;\n\n    & .listItemIcon {\n      min-width: auto;\n    }\n  }\n}\n\n.mainContainer {\n  height: calc(100vh - $navbar);\n  margin-left: $sidebarWidth;\n  margin-top: $navbar;\n  overflow: auto;\n  width: calc(100% - $sidebarWidth);\n  padding: 20px;\n\n  @media screen and (max-width: 767px) {\n    margin-left: $smallSidebarWidth;\n    width: calc(100% - $smallSidebarWidth);\n    padding: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbarContainer": `layout_navbarContainer__54CXx`,
	"navLogo": `layout_navLogo__0YZzT`,
	"sidebarContainer": `layout_sidebarContainer__I8Gym`,
	"listItemIcon": `layout_listItemIcon__qaLYI`,
	"mainContainer": `layout_mainContainer__G42Gv`
};
export default ___CSS_LOADER_EXPORT___;
